<template>
  <div>
    <v-row align="center">
      <v-col cols="auto" class="pr-12">
        <h2>Payments</h2>
      </v-col>
      <v-col>
        <v-row align="center" justify="end" no-gutters>
          <v-btn
            small
            depressed
            color="green white--text"
            @click="$refs.paymentForm.openForm()"
          >
            <v-icon small left>mdi-plus</v-icon>Add Payment
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-sheet outlined>
      <v-data-table
        :headers="tableHeaders"
        :items="itinerary.payments"
        no-data-text="There have been no payments recorded to date"
      >
        <template v-slot:item.amount="{ item }">£{{ item.amount }}</template>
        <template v-slot:item.payment_reason="{ item }">
          {{
            item.payment_reason.charAt(0).toUpperCase() +
              item.payment_reason.slice(1)
          }}
        </template>
        <template v-slot:item.credit_card_fee="{ item }">
          <v-chip
            label
            small
            :color="item.has_credit_card_fee ? 'green' : 'red'"
            text-color="white"
          >
            {{ item.has_credit_card_fee ? "Yes" : "No" }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                class="mr-2"
                v-on="on"
                @click="$refs.paymentForm.openForm(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-sheet>
    <v-dialog v-model="deleteDialog.open" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete Payment</v-card-title>
        <v-card-text>Are you sure you want to delete this payment?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteDialog.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="warningDialog.open" max-width="500px" persistent>
      <v-card>
        <v-card-title class="headline">{{ warningDialog.title }}</v-card-title>
        <v-card-text>
          <p v-html="warningDialog.message"></p>
          <p class="mb-0">Do you agree to these changes?</p>
        </v-card-text>
        <v-card-actions class="pl-6 pr-6 pb-6">
          <v-btn color="error" depressed @click="confirmWarning(false)"
            >No</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" depressed @click="confirmWarning(true)"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <PaymentForm ref="paymentForm" />
  </div>
</template>

<script>
import PaymentForm from "../../components/PaymentForm";
import _ from "lodash";

export default {
  components: {
    PaymentForm,
  },

  data() {
    return {
      tableHeaders: [
        { text: "Date Paid", value: "formatted_dates.date" },
        { text: "Amount Paid", value: "amount" },
        { text: "Payment Type", value: "payment_type" },
        { text: "Credit Card Fee", value: "credit_card_fee" },
        { text: "Payment Reason", value: "payment_reason" },
        { text: "Payment Description", value: "description" },
        { text: "Actions", value: "actions", align: "right", sortable: false },
      ],
      deleteDialog: {
        open: false,
        loading: false,
        payment: {},
        fields: {
          changeStatus: false,
        },
      },
      warningDialog: {
        open: false,
        loading: false,
        confirmed: false,
        message: "",
        title: "",
      },
    };
  },

  computed: {
    itinerary() {
      return this.$store.state.drum.tours["itinerary"];
    },

    tour() {
      return this.$store.state.drum.tours["tour"];
    },
  },

  methods: {
    openDelete(payment) {
      this.deleteDialog.payment = payment;
      this.deleteDialog.open = true;
    },

    resetDelete() {
      this.deleteDialog.loading = false;
      this.deleteDialog.open = false;
      this.deleteDialog.payment = {};
      this.deleteDialog.fields.changeStatus = false;
    },

    saveDelete() {
      const appId = this.$route.params.id;
      const tourId = this.$route.params.tourId;
      const itineraryId = this.$route.params.itineraryId;
      this.deleteDialog.loading = true;

      if (this.requiresWarning() === true) {
        this.warningDialog.open = true;
        return;
      }

      this.$store
        .dispatch("drum/tours/deleteItineraryPayment", {
          appId,
          tourId,
          itineraryId,
          paymentId: this.deleteDialog.payment.id,
          fields: this.deleteDialog.fields,
        })
        .then(() => {
          const changeStatus = this.deleteDialog.fields.changeStatus;
          this.resetDelete();

          if (changeStatus === true) {
            this.$router.push({
              name: "module-drum-proposals-itinerary-payments",
              params: {
                tourId: this.$route.params.tourId,
                itineraryId: this.$route.params.itineraryId,
              },
            });
          }
        })
        .catch(() => (this.loading = false));
    },

    requiresWarning: function() {
      if (this.warningDialog.confirmed === true) {
        return false;
      }

      if (this.itinerary.payments.length === 1 && this.tour.status === "tour") {
        this.warningDialog.message = `By removing this payment, this Itinerary's status will be changed to
            <strong>Accepted</strong>.`;

        const prices = _.cloneDeep(this.itinerary.pricing);
        const noAcceptedPrices = prices.every(price => {
          return price.status !== "Accepted";
        });

        if (noAcceptedPrices === true) {
          this.warningDialog.message += ` This tour will be downgraded to a <strong>Proposal</strong>.`;
          this.warningDialog.title = "Itinerary & Tour Status Change";
        } else {
          this.warningDialog.title = "Itinerary Status Change";
        }
        return true;
      }

      return false;
    },

    confirmWarning: function(changeStatus) {
      this.deleteDialog.fields.changeStatus = changeStatus;
      this.warningDialog.open = false;
      this.warningDialog.confirmed = true;
      this.saveDelete();
    },
  },
};
</script>
