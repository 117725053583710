<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="600px"
      @click:outside="resetForm()"
    >
      <v-card>
        <v-card-title class="headline">Add a new Payment</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5 grey lighten-4">
          <v-form @submit.prevent="saveForm" method="POST" id="payment-form">
            <v-text-field
              label="Date Paid"
              v-model="fields.date"
              type="date"
              outlined
              background-color="white"
            ></v-text-field>
            <v-text-field
              label="Amount"
              v-model="fields.amount"
              type="number"
              prepend-inner-icon="mdi-currency-gbp"
              outlined
              background-color="white"
            ></v-text-field>
            <v-select
              label="Payment Type"
              v-model="fields.payment_type"
              :items="paymentTypes"
              item-value="value"
              item-text="text"
              outlined
              background-color="white"
            ></v-select>
            <v-checkbox
              label="Credit Card Fee"
              v-model="fields.credit_card_fee"
              class="mt-0"
            ></v-checkbox>
            <v-select
              label="Payment Reason"
              v-model="fields.payment_reason"
              :items="paymentReasons"
              item-value="value"
              item-text="text"
              outlined
              background-color="white"
            ></v-select>
            <v-text-field
              label="Payment Description"
              v-model="fields.description"
              outlined
              background-color="white"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
          <v-btn
            color="accent"
            text
            type="submit"
            :loading="loading"
            form="payment-form"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="warningDialog.open" max-width="500px" persistent>
      <v-card>
        <v-card-title class="headline">Itinerary Status Change</v-card-title>
        <v-card-text>
          <p>
            By adding a Payment, this Itinerary's status will be changed to
            <strong>Confirmed</strong> and this Proposal will be turned into a
            <strong>Tour</strong>.
          </p>
          <p class="mb-0">Do you agree to these changes?</p>
        </v-card-text>
        <v-card-actions class="pl-6 pr-6 pb-6">
          <v-btn color="error" depressed @click="confirmWarning(false)"
            >No</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" depressed @click="confirmWarning(true)"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      paymentTypes: [
        { text: "Amex", value: "amex" },
        { text: "Bank Transfer", value: "bank_transfer" },
        { text: "Credit Card", value: "credit_card" },
        { text: "Other", value: "other" },
      ],
      paymentReasons: [
        { text: "Deposit", value: "deposit" },
        { text: "Balance", value: "balance" },
        { text: "Other", value: "other" },
      ],
      fields: {
        date: null,
        amount: null,
        payment_type: null,
        credit_card_fee: false,
        payment_reason: null,
        description: null,
        changeStatus: false,
      },
      errors: {},
      payment: {},
      warningDialog: {
        open: false,
        loading: false,
        confirmed: false,
      },
    };
  },

  computed: {
    itinerary() {
      return this.$store.state.drum.tours["itinerary"];
    },

    tour() {
      return this.$store.state.drum.tours["tour"];
    },
  },

  methods: {
    openForm: function (payment = null) {
      if (payment !== null) {
        this.payment = payment;
        this.isEditing = true;
        this.fields.date = payment.date;
        this.fields.amount = payment.amount;
        this.fields.payment_type = payment.payment_type;
        this.fields.credit_card_fee = payment.has_credit_card_fee;
        this.fields.payment_reason = payment.payment_reason;
        this.fields.description = payment.description;
      }

      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields.date = null;
      this.fields.amount = null;
      this.fields.payment_type = null;
      this.fields.credit_card_fee = false;
      this.fields.payment_reason = null;
      this.fields.description = null;
      this.fields.changeStatus = false;
      this.errors = {};
      this.payment = {};
      this.warningDialog = {
        open: false,
        loading: false,
        confirmed: false,
      };
    },

    saveForm: function () {
      this.loading = true;
      this.errors = {};

      if (this.requiresWarning() === true) {
        this.warningDialog.open = true;
        return;
      }

      let payload = {
        appId: this.$route.params.id,
        tourId: this.$route.params.tourId,
        itineraryId: this.$route.params.itineraryId,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.paymentId = this.payment.id;
      }

      this.$store
        .dispatch("drum/tours/saveItineraryPayment", {
          ...payload,
        })
        .then(() => {
          const changeStatus = this.fields.changeStatus;
          this.resetForm();

          if (changeStatus === true) {
            this.$router.push({
              name: "module-drum-tours-itinerary-payments",
              params: {
                tourId: this.$route.params.tourId,
                itineraryId: this.$route.params.itineraryId,
              },
            });
          }
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    requiresWarning: function () {
      if (this.warningDialog.confirmed === true || this.isEditing === true) {
        return false;
      }

      if (
        this.itinerary.status !== "Confirmed" &&
        this.tour.status !== "tour"
      ) {
        return true;
      }

      return false;
    },

    confirmWarning: function (changeStatus) {
      this.fields.changeStatus = changeStatus;
      this.warningDialog.open = false;
      this.warningDialog.confirmed = true;
      this.saveForm();
    },
  },
};
</script>